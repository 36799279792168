import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import { Col,Row,Image,Button,Carousel,Card,Container } from "react-bootstrap"
import { Parallax, Background } from "react-parallax"
import Section from "../components/Section/Section.component"

const Services = () => {
  return (

    
    <div>

      <Container style={{paddingLeft:"5px",paddingRight:"5px"}}>
        <h1 >Services</h1>
      
      <Container className="text-overlay">
          <h4>What We Do</h4>
          <p>
          Associated with global technology leaders, we deliver cutting edge resolutions with obligation. We can deliver customized turnkey solutions with accountability till handover stage. Our engineering capability empowers us to integrate Latest technology and know how of systems to deliver best possible results.
          </p>
      </Container>
  
      <Container >
          <br/>
          <Row>
            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services 5. cathodic protection.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle"></div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Engineering Design, Procurement, Installation, Commissioning, Testing & Maintenance</span></p>
                  </div>
                </div>
            
            </Col>

            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services 6. Technical Consultancy.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle"></div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Technical Consultancy, Feasibility Sturdy, Survey & Certifications.</span></p>
                  </div>
                </div>
            
            </Col>

            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services 7. Fire Fighting.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle" style={{padding:"10px", paddingTop:"40%"}}>Fire Fighting Systems</div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Turnkey solutions related to Clean Agent Fire Suppression System, Fixed Fire Fighting & Monitoring Systems</span></p>
                  </div>
                </div>
            
            </Col>

      
          </Row>
         
          <br/>
          <br/>
          <Row>

          <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services 8. TURNKEY.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle"></div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Engineering Design, Material Procurement, Installation, Supervision, Commissioning, Testing & Maintenance Services</span></p>
                  </div>
                </div>
            
            </Col>

            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services 9. Consolidated supply.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle" style={{padding:"10px", paddingTop:"40%"}}>Consolidated Supply</div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Supply of OEM Equipment’s / Parts & supplies for Industrial, Oil & gas, Offshore & Marine Industries</span></p>
                  </div>
                </div>
            
            </Col>


            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services REDUCE OPEX.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle" style={{padding:"10px", paddingTop:"30%"}}>Reduce OPEX & Enhance Asset Reliability</div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Condition Based Monitoring (CBM) & Predictive Maintenance (PdM) Solutions</span></p>
                  </div>
                </div>
            
            </Col>

           
          </Row>
          <br/>
          <br/>
          <Row>

          <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services Instrumentation & Control Systems.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle" style={{padding:"10px", paddingTop:"35%"}}>Instrumentation & Control Systems</div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>Custom Design, Turnkey Solutions for Industrial Instrumentation & Control Systems</span></p>
                  </div>
                </div>
            
            </Col>

            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../Services Customized Technical Solutions.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle" style={{padding:"10px", paddingTop:"20%"}}>Customised Technical Solutions, Optimization & Retrofitting</div>
                  <div class="cornerLink circle" >
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}} ><span>Related to Ships Ballast Water Treatment Systems & Exhaust Gas Cleaning Systems</span></p>
                  </div>
                </div>
            
            </Col>
            <Col >
                
                <div class="imageWrapper">
                  <Image src="../../training.jpg" roundedCircle  width="340px" height="340px"/>
                  <div className="imageWrapper circleTitle"></div>
                  <div class="cornerLink circle">
                    <p  align="center" style={{padding:"10px", paddingTop:"10%"}}><span>We arrange Training of clients personal on Operation & Maintenance of OEM Equipment</span></p>
                  </div>
                </div>
            
            </Col>
           
          
          </Row>
      </Container>  

      </Container>

         
    </div>
  )
}

export default Services
